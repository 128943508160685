<!--  -->
<template>
  <div class="addSupplies">
    <van-form class="main-wrapper" ref="publishNotice">
      <van-field
        required
        v-model="ruleForm.osName"
        name="osName"
        label="名称"
        placeholder="请输入办公用品名称"
        maxlength="20"
      />
      <van-field
        required
        v-model="ruleForm.numCount"
        type="number"
        placeholder="请输入数量"
        label="数量"
        maxlength="6"
      />

      <van-field
        required
        readonly
        clickable
        v-model="osType"
        name="osType"
        label="类型"
        placeholder="请选择类型"
        maxlength="50"
        @click="showOsType = true"
        right-icon="arrow"
      />

      <van-popup v-model="showOsType" round position="bottom">
        <van-picker
          v-model="osType"
          title="请选择类型"
          show-toolbar
          :columns="osTypeOptions"
          @confirm="onOsTypeFinish"
          @cancel="showOsType = false"
          value-key="dictValue"
        />
      </van-popup>

      <van-field
        required
        v-model="ruleForm.modelType"
        name="modelType"
        label="型号"
        placeholder="请输入型号"
        maxlength="20"
      />

      <van-field
        readonly
        clickable
        v-model="ruleForm.purchaseDate"
        name="purchaseDate"
        label="采购日期"
        placeholder="请选择采购日期"
        maxlength="50"
        @click="showPurchaseDate = true"
        right-icon="arrow"
      />
      <van-popup v-model="showPurchaseDate" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          :min-date="minDate"
          :max-date="maxDate"
          title="选择日期"
          @confirm="purchaseDateConfirm"
          @cancel="showPurchaseDate = false"
        />
      </van-popup>

      <div class="card">
        <div class="sub-title">备注</div>
        <textarea
          v-model="ruleForm.remarkDesc"
          name="remarkDesc"
          placeholder="请输入备注"
          maxlength="120"
          cols="30"
          rows="5"
          class="text-area"
        />
      </div>

      <div class="upload-box">
        <div class="sub-title">图片（ 非必填，最多三张）</div>
        <van-uploader
          v-model="fileList"
          :max-size="10 * 1024 * 1024"
          :before-read="beforeRead"
          :after-read="afterRead"
          @oversize="onOversize"
          class="upload"
          :max-count="3"
        />
      </div>
    </van-form>
    <div class="bottom">
      <div class="button" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import Schema from "async-validator";

import { uploadImgNoPrefix } from "@/api/cdn";
import { Toast } from "vant";
import { getDictListByCode } from "@/api/notice/index.js";

import {
  saveOfficeSupplies,
  editOfficeSupplies,
  queryOfficeSuppliesInfo,
} from "@/api/officeSupplies/index.js";

export default {
  data() {
    return {
      ruleForm: {
        modelType: "",
        numCount: "",
        osName: "",
        osPic: "",
        osType: "",
        remarkDesc: "",
        purchaseDate: "",
      },

      purchaseDate: "",
      showOsType: false,
      fileList: [],
      osType: "",
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(2040, 11, 31),
      currentDate: new Date(),
      showPurchaseDate: false,

      osTypeOptions: [],
      rules: {
        osName: [{ required: true, message: "请输入办公用品名称" }],
        numCount: [{ required: true, message: "请输入数量" }],
        osType: [{ required: true, message: "请选择类型" }],
        modelType: [{ required: true, message: "请输入型号" }],
      },
      validator: null,
    };
  },

  components: {},

  computed: {},

  async mounted() {
    this.initValidator();
    await this.getOsTypeOptions();
    if (this.$route.query.osCode) {
      this.getInfo();
    }
  },

  methods: {
    initValidator() {
      this.validator = new Schema(this.rules);
    },
    // 返回布尔值
    beforeRead(file) {
      if (["image/jpeg", "image/png"].indexOf(file.type) == -1) {
        Toast("请上传 jpg/png 格式图片");
        return false;
      }
      return true;
    },
    getInfo() {
      queryOfficeSuppliesInfo({
        osCode: this.$route.query.osCode,
      }).then((res) => {
        let data = res.data;
        this.ruleForm = {
          modelType: data.modelType,
          numCount: data.numCount,
          osCode: data.osCode,
          osName: data.osName,
          osType: data.osType,
          remarkDesc: data.remarkDesc,
          purchaseDate: data.purchaseDate,
        };

        let osTypeObj = this.osTypeOptions.find((item) => {
          return item.dictKey == data.osType;
        });
        this.osType = osTypeObj.dictValue;

        if (data.picList) {
          this.fileList = data.picList.map((item) => {
            return {
              ...item,
              resData: {
                fileCode: item.code,
              },
            };
          });
        }
      });
    },

    onOversize(file) {
      Toast("文件大小不能超过 10M");
    },
    afterRead(file) {
      let params = new FormData();
      params.append("file", file.file);
      Toast.loading({
        message: "文件上传中...",
        forbidClick: true,
        duration: 0,
      });
      uploadImgNoPrefix(params)
        .then((res) => {
          Toast.clear();
          Toast("上传成功");
          file.resData = res.data;
        })
        .catch((e) => {
          Toast.clear();
          Toast("上传失败");
        });
    },
    submit() {
      const imgArr = this.fileList.map((item) => {
        return item.resData.fileCode;
      });
      let postData = {
        ...this.ruleForm,
        osPic: imgArr.toString(),
      };

      let osCode = this.$route.query.osCode;
      this.validator
        .validate(postData)
        .then((res) => {
          if (osCode) {
            this.edit(postData);
          } else {
            this.add(postData);
          }
        })
        .catch((e) => {
          Toast(e.errors[0].message);
        });
    },
    //添加
    add(parmas) {
      saveOfficeSupplies(parmas).then((res) => {
        Toast("添加成功");
        this.$router.back();
      });
    },
    // 编辑
    edit(parmas) {
      editOfficeSupplies(parmas).then((res) => {
        Toast("编辑成功");
        this.$router.back();
      });
    },

    async getOsTypeOptions() {
      await getDictListByCode({
        code: "office_type",
      }).then((res) => {
        this.osTypeOptions = res.data;
      });
    },

    onOsTypeFinish(e) {
      this.ruleForm.osType = e.dictKey;
      this.osType = e.dictValue;
      this.showOsType = false;
    },
    purchaseDateConfirm(e) {
      this.showPurchaseDate = false;
      let year = e.getFullYear();
      let month = e.getMonth() + 1;
      let day = e.getDate();
      this.ruleForm.purchaseDate = `${year}-${String(month).padStart(
        2,
        "0"
      )}-${String(day).padStart(2, "0")}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.addSupplies {
  min-height: 100%;
  background: #fafafa;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  .sub-title {
    color: #646566;
    font-size: 14px;
    margin-bottom: 0.2rem;
  }
  .card {
    background: #fff;
    padding: 0.2rem 0.3rem;

    .tag-ul {
      margin-top: 0.2rem;
      margin-bottom: 0.3rem;
    }

    .text-area {
      background: #f6fafe;
      border-radius: 0.1rem;
      width: 100%;
      padding: 0.2rem;
    }

    .add-img {
      height: 1rem;
      width: 1rem;
    }
  }
  .upload-box {
    background: #fff;
    padding: 0.2rem;
    margin-bottom: 0.2rem;
  }
  .bottom {
    padding: 0.2rem 0.3rem;
    .button {
      width: 6.9rem;
      height: 0.8rem;
      background: #0094ee;
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
    }
  }
}
</style>
